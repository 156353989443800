import React from "react";
import Header from "components/shared/Header";
import Layout from "components/shared/Layout";
import FeaturesCards from "components/shared/FeaturesCards";
import Footer from "components/shared/Footer";
import Nav from "components/shared/Nav";
import Meta from "components/shared/Meta";

import {entryContent} from "./index.module.less";

export default () => {
  return (
    <>
      <Meta url="/Terms adn conditions/" />
      <Nav />
      <Header>
        <h1>般条款和条件</h1>
      </Header>
      <Layout>
        {/* <div className={entryContent}> */}
        <div className={entryContent}>
            <ol>
            <li>
                <bold>《一般条款和条件》</bold>
                <br />1.1在使用 LetsGrow.com.cn. 直接或间接拥有或运营的网站、产品或服务之前（简称“LetsGrow.com.cn”），请留意并同意我方的《销售及交付一般条款和条件》。
                <br />1.2本《一般条款和条件》是 LetsGrow.com.cn 与其客户之间的要约和协议的一部分。此处所指的“客户”系指 LetsGrow.com.cn 向其提供报价或与之签订协议的对象（如合作伙伴和经销商）以及 LetsGrow.com.cn 和 MyLetsGrow（LetsGrow.com.cn 的网站门户）的最终用户。除双方以书面形式另行明确规定外，本条款和条件的所有规定在双方之间均有效。
            </li>
            <li>
                <bold>报价和协议</bold>
                <br />2.1 LetsGrow.com.cn
                所有要约均须经确认，但不施加义务。仅在 LetsGrow.com.cn 接受客户的书面订单或
                LetsGrow.com.cn 开始执行该订单时，方可视为已签订协 议。
            </li>
            <li>
                <bold>网站门户的客户权利</bold>
                <br />3.1 通过 LetsGrow.com.cn 的 MyLetsGrow
                网站门户使用该软件是一种被称之为 SaaS 的解决方案，即软件作为一种服务（“门户网站”）。这意味着客户本身并不会获 得该软件；而是，该软件是作为在线服务提供的。 在
                SaaS 解决方案中，LetsGrow.com.cn 从无义务提供包含软件的实物载体，例如 目标代码，并可能使用第三方发布者来商业化其
                SaaS 解决方案（“发布者”）。
                <br />3.2
                .客户必须采取一切必要的措施以确保准确、及时地获得访问该网络应用程序的权限 。
                <br />3.3
                使用该网络应用程序，可能需要一个应用程序编程接口
                (API)。禁止与第三方分享 为此目的提供的密钥。提供或创建的密码具有唯一性，亦不得与第三方共享。
                <br />3.4
                客户可以在 MyLetsGrow 中为其客户创建账号，或者通过发布者或要求 LetsGrow.com.cn
                代为创建。为每位客户创建的各子账号仅限一人登录。禁止允许 第三方使用由
                LetsGrow.com.cn 提供的 SaaS 解决方案。
                <br />3.5
                子账号的总数可以按照订单确认加以限制。
                <br />3.6 LetsGrow.com.cn
                将在网络应用程序所使用的互联网域名中创建或促成创建一个子 域名，并将确保客户能够通过该子域名访问网络应用程序。
                <br />3.7
                客户对其使用门户网站时产生的内容（包括但不限于消息、评论、姓名等）承担全 部责任。客户保证上述内容不违反任何法律、法规、政策、公共秩序和道德准则。 否则，客户需采取措施如删除违法内容或通知
                LetsGrow.com.cn 和/或发布者等。
                <br />3.8. 未经 LetsGrow.com.cn
                事先批准，客户不得使用任何插件。
                <br />3.9
                在门户网站的使用过程中，客户不得参与或协助包括但不限于下述违法行为：<br />i.
                违反中华人民共和国宪法基本原则的行为；<br />ii.
                危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一的行为；<br />iii.
                有损国家荣誉和利益的行为；<br />iv.
                煽动民族仇恨、引起民族歧视和破坏民族团结的行为；<br />v.
                违反国家宗教政策或者宣扬邪教、封建迷信的行为；<br />vi.
                散布谣言，扰乱社会秩序，破坏社会稳定的行为；<br />vii.
                传播淫秽、色情、赌博、暴力、杀人、恐怖或者教唆犯罪的行为；<br />viii.
                侮辱、诽谤他人，侵害他人合法权益的行为；<br />ix.
                侵犯知识产权或者商业秘密等合法权利的行为；<br />x.
                为误导、欺骗而恶意隐瞒真相的任何行为；<br />xi.
                任何发布、传输、传播广告信息及垃圾邮件的行为；<br />xii.
                法律、法规禁止的其他行为。
                <br />3.10.
                客户不得实施或协助进行以下行为：<br />i.
                删除、隐藏或更改任何专利、版权、商标或其他所有权声明；<br />ii.
                干扰或意图干扰门户网站或门户网站的任何部分或组件的正常运行，或规定或公布 进行上述操作的工具或方法；<br />iii.
                避免或试图避免任何内容保护系统；<br />iv. 未经 LetsGrow.com.cn
                或发布者书面同意，以任何形式使用门户网站的网页链接地 址或技术接口；<br />v.
                直接联系使用或向 LetsGrow.com.cn 客户发送商业广告或垃圾邮件；<br />vi.
                提供追踪工具，允许客户（包括但不限于）识别访问或点击门户网站的其他客户；<br />vii.
                自动更改浏览器设置以将窗口定向至其他网站；<br />viii.
                未经授权获取发布者产品或服务的访问权；<br />ix.
                含有可能损害任何一方利益和信息安全的计算机病毒、木马程序或者其他恶意程序 ；<br />x.
                设置或者发布违反有关规定、社会秩序和道德、社会公德的门户网站或者内容；<br />xi.
                未经发布者书面同意，公开表示与发布者存在合作关系，包括但不限于交叉持股、 商业关系或合伙等；<br />xii.
                发布者或开发方认为不合适的其他活动或内容。
            </li>
            <li>
                <bold>服务提供</bold>
                <br />4.1 无论是否涉及 SaaS
                解决方案、培训课程、数据分析、建议或使用监测， LetsGrow.com.cn
                均尽最大努力向客户提供全部服务。
                <br />4.2 LetsGrow.com.cn 将尽一切努力将
                SaaS 解决方案的宕机时间降到最低。 LetsGrow.com.cn
                无法保证正常运行的确切时间。LetsGrow.com.cn 可能会变更 SaaS
                解决方案的内容、操作或范围。LetsGrow.com.cn 也可能暂时全部或部分停 用
                SaaS 解决方案，以便执行预防性、纠正性或适应性维护或其他类型的服务。 LetsGrow.com.cn
                将尽一切努力将停运工作降至最低。LetsGrow.com.cn 将适时向 客户发送通知消息，告知变动、维护或停用。LetsGrow.com.cn
                无义务专门为客户 维护、修改或增加 SaaS
                解决方案中的某些特色或功能。
                <br />4.3 LetsGrow.com.cn
                为客户的员工提供培训课程，以便其就可通过 LetsGrow.com.cn 上的 SaaS
                解决方案获得数据，掌握使用该数据的选项。LetsGrow.com.cn 可对客 户的员工获得培训和其他支持服务必须具备的资格设定要求。客户始终仅负责正确 使用
                SaaS 解决方案，并负责对经过 LetsGrow.com.cn 培训的如何使用 SaaS 解 决方案的专业人员做出部署。
                <br />4.4
                对于培训、数据分析、建议和监测 SaaS
                解决方案的使用等支持服务，客户始终对 在其业务流程中使用 SaaS
                解决方案完全负责。SaaS 解决方案仅对部分业务流程 提供流程支持。客户对于使用
                SaaS 解决方案，风险自负。LetsGrow.com.cn 既不 保证 SaaS
                解决方案所提供支持的正确性、完整性或及时性，也不对客户基于 SaaS
                解决方案的培训、数据分析、建议和监测所做决策造成的损失负责。
            </li>
            <li>
                <bold>信息的使用、收集和传输</bold>
                <br />5.1 LetsGrow.com.cn 服务器上的数据和信息由
                LetsGrow.com.cn 的客户提供。客户 特别同意根据适用的法规收集并向
                LetsGrow.com.cn 传输数据（无论是个人可识 别信息、关键信息、商业信息还是其他）。LetsGrow.com.cn
                仅可将此类数据和信 息用于解决问题、产品研发等内部用途。LetsGrow.com.cn
                仅在取得用户事先明确 许可后，方可向其第三方用户提供此类数据和信息。用户可随时撤销此类许可。 LetsGrow.com.cn
                对所提供的数据和信息的正确性概不负责。无论机密信息因何原 因公开，LetsGrow.com.cn
                概不对由此造成的损失负责。根据中国相关法规， LetsGrow.com.cn
                应尊重客户在数据保护及隐私方面的权利，包括但不限于客户的 知情权、查阅、更正或删除信息的权利、反对自动化处理的权利、向有关当局提出 投诉的权利，以及保护数据的保密性、完整性和可访问性的权利。
                <br />5.2.
                客户的数据由 LetsGrow.com.cn 以 5 分钟的时间间隔存储和保存，并在 LetsGrow.com.cn
                和/或发布者的服务器上存储 2 年。派生数据（白天、夜晚、24 小时数据，以及手动输入的数据）存储期限为
                4 年。
                <br />5.3. LetsGrow.com.cn
                确保定期备份服务器上的数据文件，但不对可能的数据丢失和由 此产生的后续损害负责。客户对自己数据的备份负全部责任。
                <br />5.4
                有关客户个人信息隐私和保护的更多信息可通过 info@letsgrow.com.cn 获得。
            </li>
            <li>
                <bold>提供硬件</bold>
                <br />6.1 目录、报价单、图纸等中所注明的、由 LetsGrow.com.cn
                提供硬件的尺寸和规格 仅供参考。价格以工厂交货为基础，不含增值税和其他税费。所述的交货时间无约 束力，应以
                LetsGrow.com.cn 与其供应商之间所签订协议的适用情况为准。如果 此类情况发生变化，则价格和交货时间可能相应变动。
                <br />6.2
                仅在 LetsGrow.com.cn
                收到硬件购买款项和任何额外服务的费用后，所交付硬件 的所有权才会转移至客户。在不影响此保留所有权规定的前提下，如为直接向客户 交付，则在向客户交付硬件之时，风险才转移至客户。
                <br />6.3
                LetsGrow.com.cn 仅可保证其能够向该硬件的次级供应商追回所述硬件。
            </li>
            <li>
                <bold>互联网的性质 – 不可抗力 – 系统维护</bold>
                <br />7.1
                作为通信媒介的互联网并非始终可靠，客户需自行承担使用互联网的费用和风险。 中断、错误和/或延误随时可能发生。如果发生电源故障或通信线路或设备故障（无 论该设备或此类连接是由
                LetsGrow.com.cn 或第三方管理）或由于其他原因导致 MyLetsGrow
                和相关的 LetsGrow.com.cn 或第三方设施无法使用或速度减慢，不构 成
                LetsGrow.com.cn 发生违约，且 LetsGrow.com.cn 也不对由此造成的损失负责 。客户始终负责确保妥善维护所有的通信连接、传感器、计算机、电源、摄像机和 其他辅助设备，并确保其始终充分运转。
            </li>
            <li>
                <bold>知识产权</bold>
                <br />8.1 LetsGrow.com.cn 网站和 MyLetsGrow
                网站门户受版权保护，使用时必须遵守版 权法律和其他适用法律和法规。通过网站提供的信息相关知识产权归 LetsGrow.com.cn
                或第三方所有。除非注明来源于 LetsGrow.com.cn 并在法律允 许范围内，否则不得复制、编辑、向公众披露本网站和
                MyLetsGrow.cn 网站门户 上的信息或将之用于商业目的。客户在使用或操作门户网站或
                LetsGrow.com.cn 网站时产生的所有知识产权均属于 LetsGrow.com.cn。
            </li>
            <li>
                <bold>备份</bold>
                <br />9.1 LetsGrow.com.cn
                负责确保定期备份服务器的数据文件。但是，对于客户所遭受的 任何数据丢失和由此导致的任何损害，其概不负责。
            </li>
            <li>
                <bold>禁止访问账号</bold>
                <br />10.1 如果发生下列情况，LetsGrow.com.cn
                有权在无需事先解释的情况下而禁止访问账 号或数据：<br />•
                其检测到冒用；<br />• 客户的试订阅或订阅到期；<br />•
                注册账号所使用的电子邮件地址不存在或不属于该账号的管理员或用户；<br />•
                客户被宣布破产、被法院下令暂停付款或其资产被委派了管理人或接管人；<br />•
                未在发票上注明的期限内支付订阅费用。
            </li>
            <li>
                <bold>价格</bold>
                <br />11.1LetsGrow.com.cn
                保留更新或延长订阅以及调整其他产品和服务相关费用的权利 。
                <br />11.2上述产品和服务的价格受限于具体条款和条件。
            </li>
            <li>
                <bold>连接和重新连接</bold>
                <br />12.1 LetsGrow.com.cn
                对连接新设备收取费用。原则上，对新设备连接采用的是标准 费率，但如果所述设备的连接或链接未能在预计时间内完成（比如，由于连接的 复杂性），则
                LetsGrow.com.cn 有权收取额外费用。连接第三方传感器的费用将 始终按届时适用的费率另行收取。
                <br />12.2
                如果一个设备或数据源（包括气候计算机或传感器）由于更新、重新安装、恢复 备份和/或更换气候计算机等原因而需要重新连接到
                LetsGrow.com.cn，则 LetsGrow.com.cn
                将按届时适用的费率收取费用。对此收取的费用不一定低于新 连接所需费用。
            </li>
            <li>
                <bold>定制工作和修改</bold>
                <br />13.1
                除了标准订阅服务，还可以提供定制化解决方案，如连接至另一套软件系统，或 按客户的要求修改
                SaaS 解决方案。LetsGrow.com.cn 将在定制或要求调整之前 向客户提供报价，并且仅在收到客户的书面订单（例如通过电子邮件）后方会开 始执行。
            </li>
            <li>
                <bold>订阅的期限和终止</bold>
                <br />14.1 如未明确约定其他期限，则 LetsGrow.com.cn/MyLetsGrow 订阅（即用户许可） 的最低合同期限为一年。在初始约定的期限届满后，除在遵守下文所载规定后进 行取消外，订阅将按照相同条件每次自动延长一年。
                <br />14.2
                客户必须遵守一个月的通知期规定；即，必须在合同期结束之前提前至少一个月 取消订阅。终止订阅的通知必须采用书面形式或通过电子邮件发给服务台。
                <br />14.3
                如果在合同期满之前终止，则最终客户需要支付该合同期限剩余时间的所有费 用。
                <br />14.4
                如果 LetsGrow.com.cn 合理认为客户未能以适当专业水平使用 SaaS
                解决方案、 或在其业务流程中使用时方式不当或不负责任、或此类使用对
                LetsGrow.com.cn 产生与预期相悖的培养结果，则 LetsGrow.com.cn
                保留（但无义务）发出终止通 知提前终止许可的权利。LetsGrow.com.cn
                有权在任何时候主张该项权利。如果 LetsGrow.com.cn
                未主张该项权利，也绝不构成其认可客户在任何时候以适当专 业水平或以正确或负责任的方式使用
                SaaS 解决方案。 此外，发生以下情况时，LetsGrow.com.cn
                有权立即取消本协议，且不影响其依法享 有的权利：
                <ol>
                    <li>客户发生破产、暂停付款或清算；</li>
                    <li>客户屡次逾期支付其欠 LetsGrow.com.cn 的应付款项，包括连接年费；</li>
                    <li>违反本《一般条款和条件》使用 SaaS 解决方案。</li>
                </ol>
            </li>
            
            <li>
                <bold>付款</bold>
                <br />15.1 付款取决于具体条款和条件。</li>
            <li>
                <bold>责任限制</bold>
                <br />16.1
                对于任何条款或条件下或与之有关的索赔（无论是否因合同、侵权行为（包括疏 忽）、违反法定义务、失实陈述或其他原因引起），LetsGrow.com.cn
                不对以下 负责：<br />(a)
                任何利润、收入、预期储蓄、业务或合同的损失；以及<br />(b)
                任何特殊的、间接的或后果性的损失。 无论基于何种法律依据（如非法行为、可归责的违约行为、取消或保修义务）， LetsGrow.com.cn
                对客户的累计责任最高为客户在一年内向 LetsGrow.com.cn 支 付金额的
                10%，但以绝对最高金额 [30,000]人民币为限。
                <br />16.2 如果因
                LetsGrow.com.cn 的管理人员或职能相当的下级人员故意行为或重大过失 造成损害索赔，则上述规定的责任上限或免除不适用。
            </li>

            <li>
                <bold>网站内容</bold>
                <br />17.1
                LetsGrow.com.cn
                在其网站提供概括性的信息，仅供参考并可能发生变更。尽管 LetsGrow.com.cn
                力求谨慎、细致地对待网站上发布的信息，但 LetsGrow.com.cn
                网站上出现不准确和/或不完善之处亦在所难免。
                <br />17.2
                使用本网站上的数据和信息，完全由客户自担风险。
                <br />17.3
                因数据和信息不准确或不完善或因使用、信赖或分发所述数据和信息而造成的损 害，LetsGrow.com.cn
                概不负责。
            </li>

            <li>
                <bold>隐私政策</bold>
                <br />18.1 LetsGrow.com.cn
                按照其网站最新发布的隐私政策处理客户数据。客户可以通过 LetsGrow.com.cn
                网站的引用或超链接访问其他网站；相关组织的隐私政策适用 。LetsGrow.com.cn
                建议客户在提供个人和/或公司数据之前，首先熟悉相关组织 的隐私政策。
            </li>
            <li>
                <bold>《一般条款和条件》的修订</bold>
                <br />19.1 LetsGrow.com.cn
                保留修订《一般条款和条件》的权利。其将在变化生效前至少 提前一个月通知客户。
            </li>
            <li>
                <bold style={{fontWeight:600}}>适用法律和争议解决    testing</bold>
                <br />20.1
                本《一般条款和条件》受中华人民共和国法律管辖，为本条款和条件之目的不包 括香港、澳门特别行政区及台湾。
                <br />20.2
                LetsGrow.com.cn 与客户之间的任何争议将由 LetsGrow.com.cn
                排他性选择提交 给中国国际经济贸易仲裁委员会（CIETAC）在北京进行仲裁，仲裁应根据申请仲 裁时有效的仲裁规则进行，该仲裁规则通过引用纳入本条款，条件为：<br />i.
                所有仲裁程序均须用中文进行，更广泛地说，仲裁语言须为中文；<br />ii.
                所有仲裁员均可流利使用中文；<br />iii. 本协议中文版本适用于仲裁员。
                <br />20.3.
                任何仲裁员裁决可由对被裁决方有管辖权的法院强制执行，或在被裁决一方的资 产所在地执行。
            </li>
            </ol>
        </div>
      </Layout>
      <Footer path="" />
    </>
  );
};
